import { useCallback, useEffect, useMemo, useState } from 'react';
import Box from '@mui/material/Box';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { IconButton } from '@mui/material';
import { getProductAllOrder, postAdjustment, get_total_ongkir_local } from 'service/api';
import swal from 'sweetalert';
import AdjustmentTable from './AdjustmentTable';
import Cookies from 'js-cookie';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import useDebounce from 'helpers/useDebounce';
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 1000,
  height: 1000,
  maxHeight: '85vh',
  bgcolor: 'background.paper',
  border: '1px solid lightgray',
  boxShadow: 24,
  borderRadius: '5px',
  p: 4,
};

const AdjustmentModal = ({ idOrder, status, setUpdate }) => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setPenambahan_harga(0)
    setAdjustment({
      type: '',
      value: '',
      destination_account: '',
      reason: '',
      // asuransi: 0,
      ongkir_china: 0,
      inspeksi: 0,
      packingKayu: 0,
      ongkir_international: 0,
    })
    setLoading(false);
  }
  const [sendInvoice, setSendInvoice] = useState(false);
  const [dataProduct, setDataProduct] = useState([]);
  const [image, setImage] = useState('');
  const [loading, setLoading] = useState(false);
  const [updateTotal, setUpdateTotal] = useState(false);
  const [penambahan_harga, setPenambahan_harga] = useState(0);
  const [totalPrice, setTotalPrice] = useState(0);
  const [customer, setCustomer] = useState(null)
  const [openModalCopyPayment, setOpenModalCopyPayment] = useState(false)
  const [loadingOngkir, setLoadingOngkir] = useState(false)
  const [adjustment, setAdjustment] = useState({
    type: '',
    value: '',
    destination_account: '',
    reason: '',
    // asuransi: 0,
    ongkir_china: 0,
    inspeksi: 0,
    packingKayu: 0,
    ongkir_international: 0,
  });
  const [ongkirLokal, setOngkirLokal] = useState(null);
  const [totalOngkirLokal, setTotalOngkirLokal] = useState(0);

  const GetDataProduct = async () => {
    const response = await getProductAllOrder(idOrder);
    if (response?.status === 200) {
      const data = response?.data?.products?.map((item) => {
        item.is_checked = false;
        item.addQty = 0;
        item.actualQty = item.qty;
        item.addPrice = 0;
        return item;
      });
      setCustomer(response?.data?.customer)
      setDataProduct(data);
      setOngkirLokal(response?.data?.ongkir)


    } else {
      setDataProduct([]);
      setOngkirLokal(null)
    }
    setTotalOngkirLokal(0)
  };

  const submitImage = (e) => {
    let formData = new FormData();
    formData.append('gambar', e.target.files[0]);
    fetch(`${process.env.REACT_APP_URL_API_IMAGE_UPLOAD}`, {
      method: 'POST',
      body: formData,
      headers: {
        Authorization: `Bearer ${Cookies.get('oms_token')}`,
      }
    })
      .then((response) => response.json())
      .then((data) => {
        if (data && data.status === 200) {
          setImage(data.file);
        } else {
          e.target.value = ''
          swal('Oops', "Gagal upload image! Silakan coba lagi.", 'error');
        }
      })
      .catch((error) => {
        swal('Oops', error, 'error');
      });
  };

  const handleChange = (e) => {
    let { name, value } = e.target;

    setUpdateTotal((prev) => !prev);
    setAdjustment((prev) => {
      return { ...prev, [name]: value };
    });
  };

  const isNotEmptyAdditionalQty = useMemo(() => {
    return dataProduct.some(d => Number(d.addQty) != 0)
  }, [dataProduct])

  const submitAdjustment = async () => {
    // if (adjustment.type === "") {
    //   swal('error', "Adjustment For is required", 'error');
    //   return
    // }
    // if(adjustment.destination_account === ""){
    //   swal('error', "Destination Account is Required", 'error');
    //   return
    // }
    // if(image === ""){
    //   swal('error', "Proof image is Required", 'error');
    //   return
    // }
    // if (adjustment.reason === "") {
    //   swal('error', "Reason is Required", 'error');
    //   return
    // }
    if (!(totalPrice || isNotEmptyAdditionalQty)) {
      swal('error', "Data tidak lengkap!", 'error');
      return
    }



    const { ongkir_china, inspeksi, packingKayu, ongkir_international } =
      adjustment;
    const product = dataProduct.filter((item) => item.is_checked);
    const modifyDataProduct = product.map((obj) => {
      return {
        ...obj,
        addPrice: Math.ceil(obj.addPrice),
        addQty: Number(obj.addQty),
      };
    });

    const payload = {
      id_so: idOrder,
      type: adjustment.type,
      value: parseInt(adjustment.value),
      reason: adjustment.reason,
      // proof: image,
      proof: 'default.jpg',
      sendEmail: sendInvoice,
      product: modifyDataProduct,
      destination_account: adjustment.destination_account,
      penambahan_harga: Number(penambahan_harga),
      // asuransi: Number(asuransi),
      ongkir_china: Number(adjustment.ongkir_china),
      inspeksi: Number(inspeksi),
      packing_kayu: Number(packingKayu),
      ongkir_international: Number(ongkir_international),
      ongkir_lokal: Number(totalOngkirLokal),
      totalPrice,
    };

    swal(`Are you sure? Id Order: ${idOrder}`, ` you will not be able to revert this change?`, {
      buttons: ["No!", "Yes!"],
    }).then(async (confirm) => {
      if (confirm) {
        setLoading(true);
        const response = await postAdjustment(JSON.stringify(payload));
        if (response?.status === 200) {
          handleClose();
          if (response.data.payment_link != "") {
            setOpenModalCopyPayment(response.data.payment_link)
            return
          }
          swal('Success', 'Adjustment submitted successfully', 'success');
          setUpdate((prev) => !prev);
          setLoading(false);
        } else {
          swal('error', response?.message, 'error');
          setLoading(false);
        }
      }
    })

  };

  // const handleSumTotalPrice = (dataProduct) => {
  //   const dataProductPriceNumber = dataProduct.filter((data) => {
  //     return data?.addPrice !== 0;
  //   });
  //   const dataProductSelected = dataProductPriceNumber.filter((data) => {
  //     return data?.is_checked === true;
  //   });
  //   const dataProductNumber = dataProductSelected.map((data) => {
  //     return {
  //       ...data,
  //       addPrice: parseFloat(data.addPrice),
  //     };
  //   });
  //   const sumProductPrice = dataProductNumber.reduce(
  //     (prev, current) => prev + current?.addPrice,
  //     0
  //   );

  //   return sumProductPrice;
  // };

  useEffect(() => {
    if (open) {
      GetDataProduct();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);


  useEffect(() => {
    let totalPenambahan = 0
    // let total_OngkirLokal = 0

    // const sumPrice = handleSumTotalPrice(dataProduct);

    setTotalPrice(
      Number(totalPenambahan) +
      // Number(adjustment?.asuransi) +
      Number(adjustment?.ongkir_china) +
      Number(adjustment?.inspeksi) +
      Number(adjustment?.packingKayu) +
      Number(adjustment?.ongkir_international) +
      Number(totalOngkirLokal) +
      Number(penambahan_harga)
      // Number(sumPrice)
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateTotal, dataProduct, totalOngkirLokal, penambahan_harga]);

  // useEffect(() => {
  //   let totalPenambahan = 0

  //   if (dataProduct) {
  //     dataProduct
  //       .filter(v => v.is_checked)
  //       .forEach(item => {
  //         const addQty = ~~item.addQty
  //         const actualQty = ~~item.actualQty
  //         const addPrice = ~~item.addPrice
  //         const price = ~~item.price

  //         totalPenambahan += ((actualQty + addQty) * (price + addPrice)) - (actualQty * price)
  //         // total_OngkirLokal += Math.ceil(addQty * item.weight / 1000) * ongkirLokal?.ongkir
  //       })
  //     setPenambahan_harga(totalPenambahan)
  //     // setTotalOngkirLokal(total_OngkirLokal)
  //   }

  // }, [dataProduct])

  const get_totalOngkirLokal = useCallback(async () => {
    // const product = dataProduct.filter((item) =>  item.is_checked && (item.addQty !== '' && item.addQty !== 0) );
    const product = dataProduct.filter(
      (item) => item.is_checked && item.addQty !== '' && item.addQty !== '0'
    );
    const totalAddQty = product.reduce((pV, cV) => {
      return pV + Number(cV.addQty)
    }, 0)

    if (product.length && totalAddQty > 0) {
      setLoading(true)
      const modifyDataProduct = product.map((obj) => {
        return {
          id_product: obj.id_product?.toString(),
          addQty: Number(obj.addQty),
        };
      });

      const payload = {
        id_so: idOrder,
        data: modifyDataProduct
      }

      const response = await get_total_ongkir_local(JSON.stringify(payload))
      if (response.status === 200) {
        setTotalOngkirLokal(response.data)
      }
      setLoading(false)
    } else {
      setTotalOngkirLokal(0)
    }
  }, [dataProduct])

  const debounceDataProductTotalOngkir = useDebounce(dataProduct, 1000);

  useEffect(() => {
    get_totalOngkirLokal()
  }, [debounceDataProductTotalOngkir])


  return (
    <>
      <button
        className={`py-2 px-5  ${status !== 'paid'
          ? 'bg-gray-300 text-white'
          : 'border text-blue-500 border-blue-500'
          } rounded-md text-center cursor-pointer`}
        onClick={() => {
          handleOpen();
        }}
        disabled={status !== 'paid'}>
        {/* disabled={status !== 'paid'}> */}
        Adjustment
      </button>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'>
        <Box sx={style} className='overflow-y-scroll variant-scroll'>
          <div className='flex justify-end -mt-5'>
            <IconButton onClick={handleClose} style={{ textAlign: 'right' }}>
              <CloseIcon />
            </IconButton>
          </div>
          <Typography id='modal-modal-title' variant='h6' component='h2' >
            <h1 className='font-bold'>Adjustment</h1>
          </Typography>
          <div className='mb-10 w-2/3 mt-4 font-medium'>
            <div className='flex items-center space-x-4'>
              <label className='w-1/3'>Name</label>
              <p>:</p>
              <div>{customer?.name}</div>
            </div>
            <div className='flex items-center space-x-4'>
              <label className='w-1/3'>Telephone</label>
              <p>:</p>
              <div>{customer?.phone}</div>
            </div>
            <div className='flex items-center space-x-4'>
              <label className='w-1/3'>Email</label>
              <p>:</p>
              <div>{customer?.email}</div>
            </div>
            <div className='flex items-center space-x-4 font-bold'>
              <label className='w-1/3'>ID ORDER</label>
              <p>:</p>
              <div >{idOrder}</div>
            </div>
          </div>

          <div className='space-y-5 mt-5 mb-10 w-2/3'>

            {/* <div className='flex items-center space-x-4'>
              <label className='w-1/3'>Adjustment For* </label>
              <select
                className='p-1 border border-gray-400 rounded-md focus:outline-blue w-2/3'
                name='type'
                value={adjustment.type}
                onChange={handleChange}>
                <option value=''>Choose Reason</option>
                <option value='Additional Logistic'>Additional Logistic</option>
                <option value='Additional Purchasing'>Additional Purchasing</option>
                <option value='Change Variant'>Change Variant</option>
                <option value='Wooden Packing'>Wooden Packing</option>
              </select>
            </div> */}
            {/* <div className='flex items-center space-x-4'>
              <label className='w-1/3'>Destination Account* </label>
              <select
                className='p-1 border border-gray-400 rounded-md focus:outline-blue w-2/3'
                name='destination_account'
                value={adjustment.destination_account}
                onChange={handleChange}>
                <option value=''>Choose your destination account</option>
                <option value='BCA Ocommerce (277 050 5006)'>
                  BCA Ocommerce (277 050 5006)
                </option>
                <option value='BCA Ocommerce (277 269 8080)'>
                  BCA Ocommerce (277 269 8080)
                </option>
                <option
                  value='BRI Ocommerce (0520 01 000285 30 1)
'>
                  BRI Ocommerce (0520 01 000285 30 1)
                </option>
                <option
                  value='BCA Guntur (647 051 4289)
'>
                  BCA Guntur (647 051 4289)
                </option>
              </select>
            </div> */}
            <div className='flex items-center space-x-4'>
              <label className='w-1/3 capitalize'>Penyesuaian Pesanan</label>
              <div className='flex flex-col  w-2/3'>
                <input
                  type='text'
                  value={penambahan_harga}
                  name='penambahan_harga'
                  onChange={e => { setPenambahan_harga(e.target.value) }}
                  className='w-full p-1 border border-gray-400 rounded-md focus:outline-blue '
                />
                <div className='text-xs text-red-600 pl-2'>
                  *Kosongkan apabila tidak menggunakan
                </div>
              </div>
            </div>
            {/* <div className='flex items-center space-x-4'>
              <label className='w-1/3 capitalize'>biaya asuransi</label>
              <div className='flex flex-col  w-2/3'>
                <input
                  value={adjustment.asuransi}
                  onChange={handleChange}
                  name='asuransi'
                  type='number'
                  className='w-full p-1 border border-gray-400 rounded-md focus:outline-blue'
                />
                <div className='text-xs text-red-600 pl-2'>
                  *Kosongkan apabila tidak menggunakan
                </div>
              </div>
            </div> */}
            <div className='flex items-center space-x-4'>
              <label className='w-1/3 capitalize'>biaya inspeksi</label>
              <div className='flex flex-col  w-2/3'>
                <input
                  value={adjustment.inspeksi}
                  onChange={handleChange}
                  name='inspeksi'
                  type='number'
                  className='w-full p-1 border border-gray-400 rounded-md focus:outline-blue'
                />
                <div className='text-xs text-red-600 pl-2'>
                  *Kosongkan apabila tidak menggunakan
                </div>
              </div>
            </div>
            <div className='flex items-center space-x-4'>
              <label className='w-1/3 capitalize'>biaya packing kayu</label>
              <div className='flex flex-col  w-2/3'>
                <input
                  value={adjustment.packingKayu}
                  onChange={handleChange}
                  name='packingKayu'
                  type='number'
                  className='w-full p-1 border border-gray-400 rounded-md focus:outline-blue'
                />
                <div className='text-xs text-red-600 pl-2'>
                  *Kosongkan apabila tidak menggunakan
                </div>
              </div>
            </div>
            <div className='flex items-center space-x-4'>
              <label className='w-1/3 capitalize'>Ongkir International</label>
              <div className='flex flex-col  w-2/3'>
                <input
                  value={adjustment.ongkir_international}
                  onChange={handleChange}
                  name='ongkir_international'
                  type='number'
                  className='w-full p-1 border border-gray-400 rounded-md focus:outline-blue'
                />
                <div className='text-xs text-red-600 pl-2'>
                  *Kosongkan apabila tidak menggunakan
                </div>
              </div>
            </div>
            <div className='flex items-center space-x-4'>
              <label className='w-1/3 capitalize'>Ongkir Lokal China</label>
              <div className='flex flex-col  w-2/3'>
                <input
                  value={adjustment.ongkir_china}
                  onChange={handleChange}
                  name='ongkir_china'
                  type='number'
                  className='w-full p-1 border border-gray-400 rounded-md focus:outline-blue'
                />
                <div className='text-xs text-red-600 pl-2'>
                  *Kosongkan apabila tidak menggunakan
                </div>
              </div>
            </div>
            <div className='flex items-center space-x-4'>
              <label className='w-1/3'>
                Ongkir Lokal<br />
                {ongkirLokal &&
                  <small className='text-gray-500'>( <span className='capitalize'>{ongkirLokal?.kurir}</span> - {ongkirLokal?.service} )</small>
                }
              </label>
              <div className='flex flex-col  w-2/3'>
                <input
                  type='text'
                  value={totalOngkirLokal.toLocaleString('id-ID')}
                  disabled
                  // onChange={handleChange}
                  className='w-full p-1 border border-gray-400 rounded-md focus:outline-blue '
                />
                <div className='text-xs text-red-600 pl-2'>
                  *Kosongkan apabila tidak menggunakan
                </div>
              </div>
            </div>
            <div className='flex items-center space-x-4'>
              <label className='w-1/3'>Total Adjustment (IDR)</label>
              <input
                type='text'
                value={totalPrice.toLocaleString('id-ID')}
                disabled
                onChange={handleChange}
                className='p-1 border border-gray-400 rounded-md focus:outline-blue w-2/3'
              />
            </div>
            {/* <div className='flex items-center space-x-4'>
              <label className='w-1/3'>Choose file</label>
              <input
                accept='image/*'
                id='icon-button-file'
                onChange={(e) => submitImage(e)}
                type='file'
                className='p-1 border border-gray-400 rounded-md focus:outline-blue w-2/3'
              />
            </div> */}
            <div className='flex space-x-4'>
              <label className='w-1/3'>Reason</label>
              <div className='w-2/3'>
                <textarea
                  rows='5'
                  className='p-1 border border-gray-400 rounded-md focus:outline-blue w-full'
                  name='reason'
                  value={adjustment.reason}
                  onChange={handleChange}
                />
                <div className='flex items-center space-x-2 mt-2'>
                  <input
                    type='checkbox'
                    name='sendInvoice'
                    checked={sendInvoice}
                    style={{ width: '16px', height: '16px' }}
                    onChange={() => setSendInvoice(!sendInvoice)}
                  />
                  <label>Send Invoice to customer</label>
                </div>
              </div>
            </div>
          </div>
          <AdjustmentTable
            id_so={idOrder}
            dataProduct={dataProduct}
            updateTotal={updateTotal}
            setUpdateTotal={setUpdateTotal}
            setDataProduct={setDataProduct}
            get_totalOngkirLokal={get_totalOngkirLokal}
          />

          <div className='text-center mt-5'>
            <hr />
            <button
              className={`${loading || !(totalPrice || isNotEmptyAdditionalQty)
                // || !image 
                // || !adjustment.reason
                // || !adjustment.destination_account 
                // || !adjustment.type 
                ? "bg-gray-400" : "bg-blue-400"} text-white p-1 rounded-md px-5 mt-5`}
              onClick={submitAdjustment}
              disabled={loading || !(totalPrice || isNotEmptyAdditionalQty)
                // || !image 
                // || !adjustment.reason
                // || !adjustment.destination_account 
                // || !adjustment.type
              }
            >
              {loading ? "Loading..." : "Submit"}
            </button>
          </div>
        </Box>
      </Modal>
      <ModalCopyPaymentLink paymentLink={openModalCopyPayment} onClose={() => {
        setOpenModalCopyPayment(false);
        setUpdate((prev) => !prev);
        setLoading(false)
      }} />
    </>
  );
};

export default AdjustmentModal;


const styleModalCopyPaymentLink = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 450,
  maxHeight: '85dvh',
  bgcolor: 'background.paper',
  border: '1px solid lightgray',
  boxShadow: 24,
  borderRadius: '5px',
  p: 2,
};

function ModalCopyPaymentLink({ paymentLink, onClose }) {
  const [isCopied, setIsCopied] = useState(false)
  const handleCopy = (paymentLink) => {
    navigator.clipboard.writeText(paymentLink)
    setIsCopied(true)
    setTimeout(() => {
      setIsCopied(false)
    }, 3000);
  }
  return (
    <Modal
      open={!!paymentLink}
      onClose={onClose}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'>
      <Box sx={styleModalCopyPaymentLink}>
        <div className="flex justify-between items-start">
          <div>

            <h1 className='text-lg font-bold'>Congratulation</h1>
            <h2 className=''>The payment link has been create</h2>
          </div>
          <button onClick={onClose}>
            <CloseRoundedIcon />
          </button>
        </div>
        <div className="flex justify-center">
          <img src="/payment.png" alt="" className='h-40 text-center' />
        </div>
        <div className="rounded bg-gray-100 m-2 p-4 mt-4">
          <div className='text-center font-bold mb-2'>Share the payment link with your customer</div>
          <div className='bg-white flex rounded border'>
            <div className='w-full whitespace-nowrap overflow-hidden p-2'>{paymentLink}</div>
            <button onClick={e => handleCopy(paymentLink)} className='bg-blue-500 px-4 font-bold text-white whitespace-nowrap flex items-center gap-1 rounded-r'><ContentCopyIcon fontSize='small' />{isCopied ? "Copied!" : "Copy"}</button>
          </div>
        </div>
      </Box>
    </Modal>
  )
}